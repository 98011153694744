import React from "react";
import * as styles from './style.module.scss';

const LoadingPulse = ({ active }) => {

    if (!active) return null;

    return <div className={styles.pulse}>
        <div></div>
        <div></div>
        <div></div>
    </div>
        ;

}

export default LoadingPulse;

