import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

// export const beforeRouteUpdate

export const onRouteUpdate = ({ location }) => {
    console.log('new pathname', location)
}

// exports.shouldUpdateScroll = () => {
//     return false;
// };