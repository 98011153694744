import { unstable_batchedUpdates } from "react-dom";
import { setNotification, storeCart } from "../store/actions";
import { dispatch } from 'react-redux'
export const CART_IDENTIFYER = '__cart';


export const saveToCart = (newItem, dispatch) => {

    let cart = getCartItems();

    let itemIsNew = true;

    cart.forEach((item, key) => {

        if (item.id === newItem.id) {
            cart[key].quantity = cart[key].quantity + newItem.quantity;
            itemIsNew = false;
        }

    });

    if (itemIsNew) {
        cart.push(newItem)
    }

    dispatch(storeCart(cart));
    dispatch(setNotification({
        show: true,
        title: 'Produkt zum Warenkorb hinzugefügt.',
        closable: true,
    }));

}

export const removeFromCart = (removeId, dispatch) => {

    let cart = getCartItems();

    let removeKey = -1;

    cart.forEach((item, key) => {
        if (item.id === removeId) {
            removeKey = key;
        }
    });

    if (removeKey > -1) {
        cart.splice(removeKey, 1);
    }

    dispatch(storeCart(cart))
}

export const changeQuantityOfItem = (itemId, newQuantity, dispatch) => {

    let cart = getCartItems();

    let itemKey = -1;

    cart.forEach((item, key) => {
        if (item.id === itemId) {
            itemKey = key;
        }
    });

    if (cart[itemKey] !== undefined) {
        cart[itemKey].quantity = newQuantity;
    }

    dispatch(storeCart(cart))
}

export const getCartItems = (item) => {

    let cart = localStorage.getItem(CART_IDENTIFYER);

    if (cart === null) {
        return [];
    }

    return JSON.parse(cart);

}

export const getCartCount = (item) => {

    return getCartItems().length;

}