import React, { useEffect, useState } from "react"
import LoadingPulse from "../components/elements/LoadingSpinner/pulse";

const LoadWrapper = ({ location, children }) => {
    const [pageLoading, setPageLoading] = useState(false);
    const [currentPath, setCurrentPath] = useState('');

    let check = false;

    useEffect(() => {

        // LOADING
        window.addEventListener("click", handleLinkClick);
        setCurrentPath(window.location.pathname);

        return () => {
            window.removeEventListener("scroll", handleLinkClick);
        };
    }, [pageLoading, currentPath]);

    const handleLinkClick = (e) => {

        if (e.target.tagName === 'A' && !e.target.className.includes('noLink')) {

            let linkClicked = e.target.href

            linkClicked = linkClicked.replace(window.location.origin, '');

            console.log("e.target", window.location.origin, linkClicked, currentPath);

            if (linkClicked !== currentPath) {
                setCurrentPath(window.location.pathname);
                setPageLoading(true);
                checkPageSwitched();
            }
        }
    }

    const checkPageSwitched = () => {

        if (window.location.pathname.toString() == currentPath) {
            setTimeout(() => {
                checkPageSwitched();
            }, 500)
        } else {
            setCurrentPath(window.location.pathname);
            setPageLoading(false);
        }
    }

    if (pageLoading) {
        return <LoadingPulse active={true} />
    } else {
        return children;
    }


}

export default LoadWrapper;