import { call, put, takeEvery } from 'redux-saga/effects'
import { CHANGE_CHECKOUT_STEP, changeCheckoutStep, STORE_CART_DATA, STORE_FORM_DATA, STORE_FAVOURITE_DATA, STORE_SHIPPING_TOTALS } from "./actions";
import { useSelector } from "react-redux";

function* syncLocalStorage(action) {
    try {
        yield call((args) => {
            const state = useSelector(state => state)
            console.log("state", state)
        }, action);
    } catch (e) {
        // yield put({type: "USER_FETCH_FAILED", message: e.message});
    }
}

function* mySaga() {
    yield takeEvery(STORE_FORM_DATA, syncLocalStorage);
    yield takeEvery(STORE_SHIPPING_TOTALS, syncLocalStorage);
    yield takeEvery(STORE_CART_DATA, syncLocalStorage);
    yield takeEvery(STORE_FAVOURITE_DATA, syncLocalStorage);
    yield takeEvery(CHANGE_CHECKOUT_STEP, syncLocalStorage);
}

export default mySaga;