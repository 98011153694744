import { CART_IDENTIFYER } from "../functions/cart";
import { FAVOURITE_IDENTIFYER } from "../functions/favourite";
import isBrowser from "../functions/isFrontend";
import { CHANGE_CHECKOUT_STEP, STORE_CART_DATA, STORE_FORM_DATA, STORE_FAVOURITE_DATA, SET_NOTIFICATION, CLOSE_NOTIFICATION, CLEAR_CART, STORE_SHIPPING_TOTALS } from "./actions";

const reducer = (state, action) => {

    if (isBrowser()) {
        switch (action.type) {
            case STORE_CART_DATA:
                state.cart = [...action.payload]
                localStorage.setItem(CART_IDENTIFYER, JSON.stringify(state.cart));
                return state
            case CLEAR_CART:
                state.cart = []
                localStorage.setItem(CART_IDENTIFYER, JSON.stringify(state.cart));
                return state
            case STORE_FAVOURITE_DATA:
                state.favourites = [...action.payload]
                localStorage.setItem(FAVOURITE_IDENTIFYER, JSON.stringify(state.favourites));
                return state
            case STORE_FORM_DATA:
                state.checkout = { ...state.checkout, ...action.payload }
                localStorage.setItem('__checkout', JSON.stringify(state.checkout));
                return state
            case STORE_SHIPPING_TOTALS:
                state.checkout.shipping.shippingTotal = action.payload
                localStorage.setItem('__checkout', JSON.stringify(state.checkout));
                return state
            case CHANGE_CHECKOUT_STEP:
                state.checkout.currentStep = action.payload.currentStep
                localStorage.setItem('__checkout', JSON.stringify(state.checkout));
                return state
            case SET_NOTIFICATION:
                state.notification = { ...state.notification, ...action.payload }
                return state
            case CLOSE_NOTIFICATION:
                state.notification = action.payload
                return state
            default:
                return state
        }
    }
}

export default reducer;