import defaultState from "./defaultState"

const STORE_CART_DATA = "CART/STORE_CART_DATA"
const CLEAR_CART = "CART/CLEAR"
const STORE_FORM_DATA = "CHECKOUT/STORE_FORM_DATA"
const CHANGE_CHECKOUT_STEP = "CHECKOUT/CHANGE_CHECKOUT_STEP"
const STORE_SHIPPING_TOTALS = "CHECKOUT/CHANGE_SHIPPING_TOTALS"
const STORE_FAVOURITE_DATA = "FAVOURITE/STORE_FAVOURITE_DATA"
const SET_NOTIFICATION = "NOTIFICATION/SET_NOTIFICATION"
const CLOSE_NOTIFICATION = "NOTIFICATION/CLOSE"

export const storeCart = payload => {
    return { type: STORE_CART_DATA, payload }
}

export const clearCart = payload => {
    return { type: CLEAR_CART, payload }
}

export const storeFavourites = payload => {
    return { type: STORE_FAVOURITE_DATA, payload }
}

export const storeFormDataAction = payload => {
    return { type: STORE_FORM_DATA, payload }
}

export const storeShippingTotal = payload => {
    return { type: STORE_SHIPPING_TOTALS, payload }
}

export const changeCheckoutStep = payload => {
    return { type: CHANGE_CHECKOUT_STEP, payload }
}

export const setNotification = payload => {
    return { type: SET_NOTIFICATION, payload }
}

export const closeNotification = () => {
    return { type: CLOSE_NOTIFICATION, payload: defaultState.notification }
}


export { STORE_FORM_DATA, CHANGE_CHECKOUT_STEP, STORE_CART_DATA, CLEAR_CART, STORE_FAVOURITE_DATA, SET_NOTIFICATION, CLOSE_NOTIFICATION, STORE_SHIPPING_TOTALS }