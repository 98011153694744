import React from "react"
import { Provider } from "react-redux"
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import createStore from "./src/store/createStore"
import { API_BASE, STRIPE_KEY } from "./src/spectory-config";
import { globalHistory } from '@reach/router'
import LoadWrapper from "./src/layouts/LoadWrapper";

// eslint-disable-next-line react/display-name,react/prop-types
const wrapper = ({ element }) => {
    const stripePromise = loadStripe(STRIPE_KEY);
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore()

    return (
        <Provider store={store}>
            <Elements stripe={stripePromise}>
                <LoadWrapper location={globalHistory}>
                    {element}
                </LoadWrapper>
            </Elements>
        </Provider>
    )
}
export default wrapper
