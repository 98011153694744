const defaultState = {
    menu: false, modal: { display: false, vimeo: 0 },
    notification: {
        show: false,
        type: 'success',
        title: '',
        onClick: null,
        closable: true
    },
    checkout: {
        currentStep: 'login',
        billing: {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            postcode: "",
            phone: "",
        },
        shipping: {
            shippingType: "pickup",
            shippingTotal: null,
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            postcode: "",
            shippingCheck: ""
        },
        payment: {
            paymentType: ""
        },
        register: {
            wantRegister: false,
            email: "",
            password: "",
        },
        additionalData:{
            couponCode: "",
            couponRest: 0.0,
            couponTotal: 0.0,
            useCustomerCardDiscount: false,
            customer_card_discount_used: 0.0,
            customerCardDiscount: 0.0,
            sumNet: 0.0,
            sum: 0.0,
            tax: 0.0,
            shipping: {},
        }
    },
    cart: [],
    favourites: [],
    authorization: {
        access: false,
        token: ""
    }
}

export default defaultState;