import { setNotification, storeFavourites } from "../store/actions";
export const FAVOURITE_IDENTIFYER = '__favourites';


export const addToFavourite = (newItem, dispatch) => {

    let favourites = getFavourites();

    let itemIsNew = true;

    favourites.forEach((item, key) => {

        if (item.id === newItem.id) {
            itemIsNew = false;
        }

    });


    if (itemIsNew) {
        favourites.push({
            id: newItem.id,
            title: newItem.title,
            price: newItem.price,
            pricePromotion: newItem.price,
            detail: '',
            destination: newItem.uri,
            removeFromFavourite: true

        })

        dispatch(setNotification({
            show: true,
            title: 'Produkt zu Favoriten hinzugefügt.',
            closable: true,
        }));

    }else{
        dispatch(setNotification({
            show: true,
            type: 'info',
            title: 'Dieses Produkt ist bereits in Favoriten gespeichert.',
            closable: true,
        }));

    }

    dispatch(storeFavourites(favourites))


}

export const removeFavourite = (itemId, dispatch) => {

    let favourites = getFavourites();

    let removeKey = -1;

    favourites.forEach((item, key) => {
        if (item.id === itemId) {
            removeKey = key;
        }
    });

    if (removeKey > -1) {
        favourites.splice(removeKey, 1);
    }

    dispatch(storeFavourites(favourites));

    dispatch(setNotification({
        show: true,
        title: 'Produkt aus Favoriten entfernt.',
        closable: true,
    }));

}

export const getFavourites = () => {

    let favourites = localStorage.getItem(FAVOURITE_IDENTIFYER);

    if (favourites === null) {
        return [];
    }

    return JSON.parse(favourites);

}

export const getFavouriteCount = () => {

    return getFavourites().length;

}