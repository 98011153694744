import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from "redux-devtools-extension";

import mySaga from './sagas'
import reducer from "./reducer"
import defaultState from "./defaultState"
import { CART_IDENTIFYER } from "../functions/cart";
import { FAVOURITE_IDENTIFYER } from "../functions/favourite";

const initialState = { menu: false, modal: { display: false, vimeo: 0 } }
// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const isBrowser = typeof window !== "undefined"

// mount it on the Store
const createStore = () => {

    let getCheckoutFromLocalStorage = {}
    let getCartFromLocalStorage = [];
    let getFavouritesFromLocalStorage = [];
    let combinedState = defaultState

    if (isBrowser) {
        getCheckoutFromLocalStorage = localStorage.getItem("__checkout") ? JSON.parse(localStorage.getItem("__checkout")) : defaultState?.checkout
        getCartFromLocalStorage = localStorage.getItem(CART_IDENTIFYER) ? JSON.parse(localStorage.getItem(CART_IDENTIFYER)) : []
        getFavouritesFromLocalStorage = localStorage.getItem(FAVOURITE_IDENTIFYER) ? JSON.parse(localStorage.getItem(FAVOURITE_IDENTIFYER)) : []
    }

    // ADD CART
    combinedState = { ...combinedState, cart: [...getCartFromLocalStorage] }
    combinedState = { ...combinedState, favourites: [...getFavouritesFromLocalStorage] }
    combinedState = { ...combinedState, checkout: { ...getCheckoutFromLocalStorage } }

    const store = reduxCreateStore(reducer, combinedState, composeWithDevTools(
        applyMiddleware(sagaMiddleware),
        // other store enhancers if any
    ))

    // then run the saga
    sagaMiddleware.run(mySaga)

    return store
}

export default createStore
