import React from "react";
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";

const Layout = ({ location, children }) => {
    return (
        <TransitionProvider
            location={location}
            mode="immediate"
            enter={{
                opacity: 0,
                config: {
                    mass: 1,
                    tension: 210,
                    friction: 20,
                    clamp: true
                },
                onRest: () => {
                    console.log("Hello, World!");
                }
            }}
            usual={{
                opacity: 1,
            }}
            leave={{
                opacity: 0,
                config: {
                    duration: 0
                },
                onStart: () => {
                    console.log("START TRANSITION")
                },
                onRest: () => {
                    console.log("REAST TRANSITION")
                }
            }}
        >
            {children}
        </TransitionProvider>
    );
};

export default Layout;