export const API_BASE = `https://wp.nimmervoll.net/wp-json/siegfried/v1`;
export const GRAPHQL_BASE = `https://wp.nimmervoll.net/graphql`;
export const FACEBOOK_PIXEL_ID = '';
export const GOOGLE_ANALYTICS_KEY = 'G-WMYC058HG3';
export const LINKEDIN_PIXEL_ID = '';

export const OFFERS_ALL_ENDPOINT = 'https://data.nimmervoll.net/o-api/offers/rand/W';

export const PRODUCTS_PER_PAGE = 12;
export const ARTICLES_PER_PAGE = 6;

export const STRIPE_KEY = 'pk_test_51Il76mGJ1lhugf3L0Gn8ukBfmdlL1PaUsaslFNPPT02Jdc4gqlVmhIdKgw0IAoQpgirCZPnK0aVVAJ1CTDsK2IDO00An2I5HEL';